<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h4">Editar Sector</span>
      </v-col>
    </v-row>
    <v-divider> </v-divider>
    <br />
    <div>
      <div v-if="this.sector">
        <SectorForm :sector="this.sector"></SectorForm>
      </div>
    </div>
  </v-container>
</template>

<script>
import SectorForm from "../components/SectorForm.vue";
import EmpleadosService from "@/services/EmpleadosService.js";

export default {
  name: "SectorEdit",
  components: {
    SectorForm,
  },
  data() {
    return {
      sector: null,
    };
  },
  props: ["id"],
  created() {
    EmpleadosService.getSector(this.id)
      .then((response) => {
        this.sector = response.data;
      })
      .catch((error) => console.log(error));
  },
};
</script>
