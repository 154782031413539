<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h4">Editar Lugar</span>
      </v-col>
    </v-row>
    <v-divider> </v-divider>
    <br />
    <div>
      <div v-if="this.lugar">
        <LugarForm :lugar="this.lugar"></LugarForm>
      </div>
    </div>
  </v-container>
</template>

<script>
import LugarForm from "../components/LugarForm.vue";
import EmpleadosService from "@/services/EmpleadosService.js";

export default {
  name: "LugarEdit",
  components: {
    LugarForm,
  },
  data() {
    return {
      lugar: null,
    };
  },
  props: ["id"],
  created() {
    EmpleadosService.getLugar(this.id)
      .then((response) => {
        this.lugar = response.data;
      })
      .catch((error) => console.log(error));
  },
};
</script>
