<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h4">Editar Empleado</span>
      </v-col>
    </v-row>
    <v-divider> </v-divider>
    <br />
    <div v-if="this.empleado">
      <EmpleadoForm :empleado="this.empleado"></EmpleadoForm>
    </div>
  </v-container>
</template>

<script>
import EmpleadoForm from "../components/EmpleadoForm.vue";
import EmpleadosService from "@/services/EmpleadosService.js";

export default {
  name: "EmpleadoEdit",
  components: {
    EmpleadoForm,
  },
  data() {
    return {
      empleado: null,
    };
  },
  props: ["id"],
  created() {
    EmpleadosService.getEmpleado(this.id)
      .then((response) => {
        this.empleado = response.data;
      })
      .catch((error) => console.log(error));
  },
};
</script>
