<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h4">Editar Dispositivo</span>
      </v-col>
    </v-row>
    <v-divider> </v-divider>
    <br />
    <div v-if="this.dispositivo">
      <DispositivoForm :dispositivo="this.dispositivo"></DispositivoForm>
    </div>
  </v-container>
</template>

<script>
import DispositivoForm from "../components/DispositivoForm.vue";
import EmpleadosService from "@/services/EmpleadosService.js";

export default {
  name: "DispositivoEdit",
  components: {
    DispositivoForm,
  },
  data() {
    return {
      dispositivo: null,
    };
  },
  props: ["id"],
  created() {
    EmpleadosService.getDispositivo(this.id)
      .then((response) => {
        this.dispositivo = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
